export default `
  query productsList($search: String = "") {
    xsearchProducts(search: $search) {
      items {
        sku
        name
        thumbnail {
            disabled
            label
            position
            url
        }
        url_key
        url_suffix
      }
    }

    xsearchCategories(search: $search) {
      items {
        name
        url
        description
      }
    }
  }
`;
